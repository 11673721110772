import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StaticDataService } from '../../../static-data.service';
import { GetDepartments } from './departments.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface DepartmentsStateModel {
  list: any[];
}

export const defaultState: DepartmentsStateModel = {
  list: [],
};

@State({
  name: 'departments',
  defaults: defaultState,
})
@Injectable()
export class DepartmentsState {
  constructor(private service: StaticDataService) {}

  @Selector()
  static list(state): any[] {
    return state.list;
  }

  @Action(GetDepartments)
  getDepartments(ctx: StateContext<DepartmentsStateModel>): Observable<any> {
    return this.service.getDepartments().pipe(tap((data: any[]) => ctx.patchState({ list: data })));
  }
}
